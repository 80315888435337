import { useLocalization } from "@shared-ui/localization-context";

export const getTitle = (title: string): any => {
  const { formatText } = useLocalization();
  switch (title) {
    case "Similar properties": {
      return formatText("hotels.dateless.similarProperties.carousel.heading.title");
    }
    default:
      return undefined;
  }
};
